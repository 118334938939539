<template>
  <VModal
    modal-inner-class="modal-table bg--white scroll-y"
    @close="emit('close')"
  >
    <h4 v-if="title" class="modal__title">{{ title }}</h4>

    <slot/>

    <VTable
      :head-row="tableHead"
      :rows="tableRows"
      :is-pagination="false"
      :is-min-height="false"
      :is-checkbox="false"
    />
  </VModal>
</template>

<script setup>
import VModal from '@/components/ui/modal/VModal'
import { defineProps, defineEmits } from 'vue'
import VTable from '@/components/ui/table/VTable'

defineProps({
  title: {
    type: String,
    required: false,
    default: ''
  },
  tableHead: {
    type: Array,
    required: true
  },
  tableRows: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['close'])

</script>
